import React,{useState,useEffect,useContext} from 'react';
import { useNavigate} from 'react-router-dom';
import { ClockContext } from './ClockContext';
import axios from 'axios';
function Login(){

  const {
    clockstate,
    handleclockstate
  } = useContext(ClockContext);

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
});
const [alertText, setAlertText] = useState('');

const navigate = useNavigate();
const [error, setError] = useState(false);
const [isEmail,setisEmail]=useState(false);
const [isPassword,setisPassword]=useState(false);
const [searching, setSearching]=useState(false);
const [myToken, setToken] = useState('');
const [myTokenID, setTokenID] = useState('');
const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({
        ...credentials,
        [name]: value,
    });
};
useEffect(() => {
    // Get cookie by name
    const getCookie = (name) => {
      const value = "; " + document.cookie;
      const parts = value.split("; " + name + "=");
      if (parts.length === 2) return parts.pop().split(";").shift();
    };

    // Get token from cookie
    const token = getCookie('token');
    const tokenID = getCookie('tokenID');
    setToken(token);
     setTokenID(tokenID);
    if (token) {
        
          validate();
    }

  }, []);
  
  const validate = async () => {
    const formData = new FormData();
    formData.append('token', myToken);
    formData.append('tokenID', myTokenID);
    
    try {
        const response = await axios.post('https://megaxsolutions.com/api/middleware/authenticate.php', formData, {
            headers: {
                'Content-Type': 'application/form-data'
            }
        });
         console.log(response.data);
        
        if(response.data[0]['success']==1){
          navigate('/dashboard');
        }else{
          
        }
    } catch (error) {
        console.error('Error submitting form', error);
    }
  };
function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  }
  

const handleSubmit = async (e) => {
    e.preventDefault();
    setSearching(true);
    if(credentials.email==''){
      setisEmail(true);
      setSearching(false);
      return;
    }
    if(credentials.password==''){
      setisPassword(true);
      setSearching(false);
      return;
    }
        const fetchData = async () => {
          console.log(JSON.stringify(credentials));
          try {
            const response = await fetch('https://megaxsolutions.com/api/middleware/login.php', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(credentials),
            });
          
            const data = await response.text();
           
            let res=JSON.parse(data);
            if(res[0]==1){
                setCookie("token", res[1], 7);
                setCookie("tokenID", res[2], 7);
                setCookie("username", res[3], 7);
                localStorage.removeItem('username');
                localStorage.setItem('username',res[3]);
                localStorage.removeItem('token');
                localStorage.setItem('token',res[1]);
                localStorage.removeItem('tokenID');
                localStorage.setItem('tokenID',res[2]);
                localStorage.removeItem('userlevel');
                localStorage.setItem('userlevel',res[4]);
                console.log("success");
                if(res[4]==1){
                  navigate('/dashboard');
                }else if(res[4]==2){
                  navigate('/ordertaking');
                }else if(res[4]==3){
                  navigate('/baker');
                }else if(res[4]==4){
                  navigate('/decorator');
                }else if(res[4]==5){
                  navigate('/manager');
                }else if(res[4]==6){
                  navigate('/delivery');
                }else if(res[4]==7){
                  navigate('/hotkitchen');
                }else if(res[4]==8){
                  navigate('/cashier');
                }else if(res[4]==9){
                  navigate('/managersnacksdelivery');
                }else if(res[4]==10){
                  navigate('/manager');
                }
                
                setTimeout(() => {
                  window.location.reload();
                }, 3);
            }else{
                console.log('Error logging in:');
                setAlertText("Invalid Credentials");
                setError(true);
            }
           
           
          } catch (error) {
            console.error('Error logging in:', error);
            setError(true);
          }
          setSearching(false);
        };
    
        fetchData();
     
};

    return(
        <>
        <main>
    <div className="container">

      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">

              <div className="d-flex justify-content-center py-4">
                <span className="logo d-flex align-items-center w-auto">
                  <img src="assets/img/ikea.jpg" alt=""/>
                  <span className="d-none d-lg-block">IKEA</span>
                </span>
              </div>

              <div className="card mb-3">

                <div className="card-body">

                  <div className="pt-4 pb-2">
                  {error? (<div className="alert alert-danger" id="alert">{alertText}</div>):''}
                    <h5 className="card-title text-center pb-0 fs-4">Login to Your Account</h5>
                    </div>
                    
                  <form className="row g-3 needs-validation" onSubmit={handleSubmit} noValidate>

                    <div className="col-12">
                    
                      <label htmlFor="yourUsername" className="form-label">Email</label>
                     
                        {isEmail?(<span className='badge bg-danger'>Required!</span>):''}
                        <input type="email" name="email" className="form-control" 
                        id="email"
                        onChange={handleChange} 
                        autoComplete="email"
                        required />
                        <div className="invalid-feedback">Please enter your username.</div>
                      
                    </div>

                    <div className="col-12">
                      <label htmlFor="current-password" className="form-label">Password</label>
                      {isPassword?(<span className='badge bg-danger'>Required!</span>):''}
                      <input type="password" name="password" className="form-control" id="yourPassword" 
                      onChange={handleChange} 
                      autoComplete="current-password" required/>
                      <div className="invalid-feedback">Please enter your password!</div>
                    </div>

                    <div className="col-12">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe"/>
                        <label className="form-check-label" htmlFor="rememberMe">Remember me</label>
                      </div>
                    </div>
                    <div className="col-12">

                      <button className="btn btn-primary w-100" disabled={searching? true:false} type="submit">Login  {searching? (<i className="fa fa-spinner fa-spin"></i>):""}</button>
                    </div>
    
                    </form>
                </div>
              </div>


            </div>
          </div>
        </div>

      </section>

    </div>
  </main>
        </>
    );
}
export default Login;