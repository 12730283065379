import React from "react";
import SideNav from "./SideNav";
import Footer from "./Footer";
import BakeradminContent from "./BakeradminContent";


function Bakeradmin(){
 return(
    <>
    <SideNav />
    <BakeradminContent />
    <Footer/>
    </>
 );
}
export default Bakeradmin;